import * as React from "react"
import '../styles/app.css'
import { StaticImage } from "gatsby-plugin-image"
import { Header } from "../components/header"
import { Box, Grid } from "@mui/material"

const IndexPage = () => {

  return (
    <div style={{ boxSizing: 'border-box', }}>
      <Header />
      {/* Regular Homepage Display */}
      <Box sx={{ display: {xs: 'none', sm: 'grid'} }}>
        <StaticImage src="../images/landing.png" style={{marginTop: '6rem', gridArea: "1/1"}} height={2160} quality={100} />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <Grid container>
            <Grid item sm={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <StaticImage src="../images/logo/logo.png" quality={100} />
            </Grid>
            <Grid item sm={12}>
              <h1>Brought to you by the following sponsors!</h1>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Grid container>
                <Grid item sm={12}  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <StaticImage src="../images/sponsors/MarshRopes.png" quality={100} height={200} />
                </Grid>
                <Grid item sm={12}>
                  <h2>Marshall Ropes</h2>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Grid container>
                <Grid item sm={12}  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <StaticImage src="../images/sponsors/WRI.png" quality={100} height={200} />
                </Grid>
                <Grid item sm={12}>
                  <h2>WRI</h2>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
        </div>
      </Box>
      {/* Mobile Page Display */}
      <Box sx={{ display: {xs: 'grid', sm: 'none'} }}>
        <StaticImage src="../images/landing.png" style={{marginTop: '6rem', gridArea: "1/1"}} height={1800} quality={100} />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
            marginTop: '6rem'
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <StaticImage src="../images/logo/logo.png" quality={100} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <h2>Brought to you by the following sponsors!</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Grid container>
                <Grid item xs={12}  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <StaticImage src="../images/sponsors/MarshRopes.png" quality={100} height={200} />
                </Grid>
                <Grid item xs={12}>
                  <h2>Marshall Ropes</h2>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Grid container>
                <Grid item xs={12}  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <StaticImage src="../images/sponsors/WRI.png" quality={100} height={200} />
                </Grid>
                <Grid item xs={12}>
                  <h2>WRI</h2>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </div>
      </Box>
    </div>
  )
}

export default IndexPage
